import React from "react";
import { useMatch, useResolvedPath } from "react-router-dom";
import styled from "@emotion/styled";

// Components
import Link from "./Link";

// Utils
import { Colors } from "../utils/constants";

const StyledLink = styled(Link)`
    display: flex;
    align-items: center;

    ${(props) =>
        props.active === "true" &&
        `
        color: ${Colors.primary};

        &:after {
            position: absolute;
            content: '';
            background: ${Colors.primary};
            height: 2px;
            width: 100%;
            left: 0;
            bottom: -12px; 
        }
    `}
`;

const NavLink = React.forwardRef(({ children, to, ...props }, ref) => {
    let resolved = useResolvedPath(to);
    let match = useMatch({
        path: resolved.pathname + resolved.hash,
        end: true,
    });

    return (
        <StyledLink active={match ? "true" : ""} to={to} {...props}>
            {children}
        </StyledLink>
    );
});

export default NavLink;
