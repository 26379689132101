import React, { useContext } from "react";

// Context
import { StateContext } from "../../../common/context/StateContext";

// Utils
import { PageGutter, Colors } from "../../../common/utils/constants";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

// Components
import Typography from "../../../common/components/Typography";
import Link from "../../../common/components/Link";
import Button from "../../../common/components/Button";
import ExternalLink from "../../components/ExternalLink";

// Images
import Logo from "../../../images/logo_light.svg";
import Fb from "../../../images/icons/ic_fb.svg";
import Inst from "../../../images/icons/ic_inst.svg";
import Linked from "../../../images/icons/ic_linked.svg";
//import Tw from '../../../images/icons/ic_tw.svg'
import Yt from "../../../images/icons/ic_yt.svg";

const Footer = () => {
    const { setIsContactFormOpen, login } = useContext(StateContext);

    // const handleLogin = (e) => {
    //     e.preventDefault()
    //     login()
    // }

    const handleNavigateLMS = () => {
        var win = window.open(process.env.REACT_APP_LMS_URL, "_self");
        win.focus();
    };

    return (
        <footer id="footer">
            <Grid
                container
                spacing={1}
                justifyContent="space-between"
                sx={{
                    background: Colors.primary,
                    padding: { xs: `8% ${PageGutter}`, sm: `4% ${PageGutter}` },
                }}
            >
                <Grid item xs={12} lg={3}>
                    <img alt="resolute education" src={Logo}></img>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1.5}>
                    <Stack spacing={4} mt={{ xs: 3, lg: 0 }}>
                        <Typography variant="h5" sx={{ color: Colors.accent }}>
                            Overview
                        </Typography>
                        <Link nounderline="true" light="true" to="/">
                            For Schools
                        </Link>
                        {/* <Link nounderline="true" light="true" to="/students">For Students</Link> */}
                        {/* <Link nounderline="true" light="true" to="/schools">For Schools</Link> */}
                        {/* <ExternalLink nounderline="true" light="true" href="" onClick={handleLogin}>Sign In / Register</ExternalLink> */}
                        {/* <ExternalLink
                            nounderline="true"
                            light="true"
                            onClick={handleNavigateLMS}
                        >
                            My Resolute
                        </ExternalLink> */}
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1.5}>
                    <Stack spacing={4} mt={{ xs: 3, lg: 0 }}>
                        <Typography variant="h5" sx={{ color: Colors.accent }}>
                            Resources
                        </Typography>
                        <Link
                            nounderline="true"
                            light="true"
                            to="#footer"
                            onClick={() => setIsContactFormOpen(true)}
                        >
                            Call Me Back
                        </Link>
                        <Link nounderline="true" light="true" to="/blog">
                            Our Blog
                        </Link>
                        {/* <Link nounderline="true" light="true" to='/#home-faq'>FAQ</Link> */}
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1.5}>
                    <Stack spacing={4} mt={{ xs: 3, lg: 0 }}>
                        <Typography variant="h5" sx={{ color: Colors.accent }}>
                            About Us
                        </Typography>
                        <Link
                            nounderline="true"
                            light="true"
                            to="/about-us#our-story"
                        >
                            Our Story
                        </Link>
                        <Link nounderline="true" light="true" to="/#home-faq">
                            School FAQ
                        </Link>
                        {/* <Link nounderline="true" light="true" to="/about-us#gallery">Gallery</Link> */}
                        {/* <Link nounderline="true" light="true" to="/schools#reviews">Reviews</Link> */}
                        {/* <Link nounderline="true" light="true" to="/#reviews">Reviews</Link> */}
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1.5}>
                    <Stack spacing={4} mt={{ xs: 3, lg: 0 }}>
                        <Typography variant="h5" sx={{ color: Colors.accent }}>
                            Legal
                        </Typography>
                        <Link
                            nounderline="true"
                            light="true"
                            to="/terms-of-service"
                        >
                            Terms & Conditions
                        </Link>
                        <Link
                            nounderline="true"
                            light="true"
                            to="/privacy-policy"
                        >
                            Privacy Policy
                        </Link>
                        {/* <Link nounderline="true" light="true" to="/shipping-and-returns">Shipping &amp; Returns</Link> */}
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1.5}>
                    <Stack spacing={3.5} mt={{ xs: 3, lg: 0 }}>
                        <Typography variant="h5" sx={{ color: Colors.accent }}>
                            Social
                        </Typography>
                        <Stack direction="row" alignItems="center" spacing={4}>
                            <a
                                href="https://www.facebook.com/resoluterobotics"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img alt="facebook" src={Fb}></img>
                            </a>
                            {/* <a href="https://www.instagram.com/resoluterobotics/" target="_blank" rel="noopener noreferrer">
                                <img alt="twitter" src={Tw}></img>
                            </a> */}
                            <a
                                href="https://www.instagram.com/resoluterobotics/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img alt="instagram" src={Inst}></img>
                            </a>
                            <a
                                href="https://www.youtube.com/channel/UCm8TKAIcRoqVacK0DugJCnw"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img alt="youtube" src={Yt}></img>
                            </a>
                            <a
                                href="https://www.linkedin.com/company/resolute-education/?originalSubdomain=za"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img alt="linkedin" src={Linked}></img>
                            </a>
                        </Stack>
                        <ExternalLink
                            light="true"
                            href="mailto:info@resolute.education"
                        >
                            info@resolute.education
                        </ExternalLink>
                        <ExternalLink light="true" href="tel:+27676090699">
                            (+27)67 609 0699
                        </ExternalLink>
                    </Stack>
                </Grid>
            </Grid>
        </footer>
    );
};

export default Footer;
