import React from "react";
import { Global, css } from "@emotion/react";

// Utils
import { Colors } from "../utils/constants";
import CssBaseline from "@mui/material/CssBaseline";

const GlobalStyle = css`
    html {
        scroll-behavior: smooth;
        background: ${Colors.primary};
    }

    body {
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        margin: 0;
        overflow-x: hidden;
    }

    .SnackbarContent-root {
        .SnackbarItem-message {
            font-family: "Poppins", sans-serif;
            font-size: 14px;
        }
        .MuiSvgIcon-root {
            color: white !important;
        }
    }
`;

const MainLayout = ({ children }) => {
    return (
        <React.Fragment>
            <Global styles={GlobalStyle} />
            <CssBaseline />
            {children}
        </React.Fragment>
    );
};

export default MainLayout;
