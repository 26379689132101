import contentInstance from "./contentApi";
import TokenService from "./token.service";
import { handleError } from "./utils";

const isBrowser = typeof window !== `undefined`;

const verifyRequestToken = (access_token) => {
    if (!isBrowser) return false;

    return contentInstance
        .post("/sso/verify_request_token/", {
            access_token: access_token,
        })
        .then((response) => {
            if (response.data.access) {
                TokenService.setUser(response.data);
                return {
                    status: true,
                    data: response.data,
                };
            }
        })
        .catch((err) => {
            return handleError(err);
        });
};

const getSSOurl = (next, register = false) => {
    if (!isBrowser) return false;

    let url = "/sso/request_token/";
    if (next && next !== "") {
        url += `?next=${next}`;
        if (register) {
            url += `&register=true`;
        }
    } else if (register) {
        url += `?register=true`;
    }

    return contentInstance
        .get(url)
        .then((response) => {
            if (response.status === 200) {
                return {
                    status: true,
                    data: response.data,
                };
            }
        })
        .catch((err) => {
            return handleError(err);
        });
};

const logout = () => {
    if (!isBrowser) return false;

    return contentInstance
        .post("/sso/logout/")
        .then((response) => {
            if (response.status === 200) {
                TokenService.removeUser();
                return {
                    status: true,
                };
            }
        })
        .catch((err) => {
            return handleError(err);
        });
};

const getCurrentUser = () => {
    return TokenService.getUser();
};

const AuthService = {
    verifyRequestToken,
    getCurrentUser,
    getSSOurl,
    logout,
};
export default AuthService;
