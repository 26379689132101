import React, { useState, useEffect, useContext } from "react";
import styled from "@emotion/styled";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

// API
import PublicService from "../../services/public.service";

// Form
import { useForm, Controller } from "react-hook-form";

// Context
import { StateContext } from "../context/StateContext";

// Utils
import { Colors } from "../utils/constants";
import mediaQuery from "../utils/mediaQuery";

// MUI
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";

// Components
import Button from "./Button";
import Input from "./Input";
import Select from "./Select";
import Typography from "./Typography";
import Loader from "./Loader";

// Images
import CloseIcon from "@mui/icons-material/Close";

// Utils
import { ValidationMessages } from "../../common/utils/constants";
import {
    isValidEmail,
    isValidContactNumber,
} from "../../common/utils/validations";

// zIndex need to be high because of swiper arrows
const Container = styled(Stack)`
    position: fixed;
    bottom: 5%;
    right: -300px;
    z-index: 998;
    transition: 0.5s ease;
    z-index: 100;

    ${mediaQuery.tablet`
        right: -360px;
    `}

    ${(props) =>
        props.active === "true" &&
        `
        right: 0 !important;
    `}
`;

const VerticalButton = styled.div`
    background: ${Colors.accent};
    color: white;
    padding: 20px 10px;
    height: fit-content;
    border-radius: 0 7px 7px 0;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    cursor: pointer;
    transition: 0.3s ease;

    transform: rotate(180deg);
    writing-mode: vertical-rl;

    &:hover {
        background: ${Colors.primary};
    }
`;

const FixedContactForm = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const { isContactFormOpen, setIsContactFormOpen, defaultContactMessage } =
        useContext(StateContext);
    const { watch, control, handleSubmit, reset, setValue } = useForm();

    const initialFormState = {
        isLoading: false,
        success: false,
        error: false,
        errorMessage: "",
    };

    const [formState, setFormState] = useState(initialFormState);
    const contactType = watch("contact_type");

    useEffect(() => {
        if (isContactFormOpen && defaultContactMessage !== "") {
            setValue("message", defaultContactMessage, { shouldDirty: true });
        }

        if (!isContactFormOpen) {
            reset({});
            setFormState(initialFormState);
        }
    }, [isContactFormOpen]);

    const onSubmit = async (values) => {
        if (!executeRecaptcha) {
            console.log("Execute recaptcha not yet available");
            return;
        }
        const token = await executeRecaptcha("contact_form");

        if (token) {
            setFormState({ ...initialFormState, isLoading: true });

            const postValues = {
                ...values,
                contact_type: contactType,
                recaptcha: token,
            };

            const PostForm = await PublicService.postContactForm(postValues);

            if (PostForm.status) {
                setFormState({
                    ...initialFormState,
                    loading: false,
                    success: true,
                });
                reset({});
            } else {
                setFormState({
                    ...initialFormState,
                    error: true,
                    errorMessage:
                        "Could not send your message. Please try again later.",
                });
                reset({});
            }
        } else {
            setFormState({
                ...initialFormState,
                error: true,
                errorMessage: "Did not pass recaptcha check",
            });
        }
    };

    return (
        <Container
            direction="row"
            alignItems="flex-end"
            spacing={0}
            active={isContactFormOpen ? "true" : ""}
        >
            <VerticalButton
                variant="contained"
                onClick={() => setIsContactFormOpen(!isContactFormOpen)}
            >
                {isContactFormOpen ? (
                    <>
                        <CloseIcon sx={{ fontSize: 18 }} /> Close
                    </>
                ) : (
                    "Call Me Back"
                )}
            </VerticalButton>
            <Paper sx={{ p: 3, width: { xs: "300px", md: "360px" } }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Stack spacing={1.5}>
                        <Typography variant="h3">Hello!</Typography>

                        <Controller
                            name="contact_type"
                            control={control}
                            defaultValue="email"
                            rules={{
                                required: "Required",
                            }}
                            render={({ field, fieldState: { error } }) => (
                                <FormControl disabled={formState.isLoading}>
                                    <FormLabel id="contact-type-label">
                                        <Typography variant="subheader">
                                            How would you like us to contact
                                            you?
                                        </Typography>
                                    </FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="contact-type-label"
                                        name="contact_type"
                                        error={error}
                                        {...field}
                                    >
                                        <FormControlLabel
                                            value="email"
                                            control={<Radio />}
                                            label="Email Me"
                                        />
                                        <FormControlLabel
                                            value="call"
                                            control={<Radio />}
                                            label="Call Me Back"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            )}
                        />

                        <Controller
                            name="name"
                            defaultValue=""
                            control={control}
                            rules={{
                                required: "Required",
                            }}
                            render={({ field, fieldState: { error } }) => (
                                <Input
                                    id="name"
                                    label="Name *"
                                    error={error}
                                    {...field}
                                />
                            )}
                        />
                        {(contactType === "email" ||
                            contactType === "video") && (
                            <Controller
                                name="email"
                                defaultValue=""
                                control={control}
                                rules={{
                                    required: ValidationMessages.required,
                                    validate: (value) =>
                                        isValidEmail(value) ||
                                        ValidationMessages.invalidEmail,
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <Input
                                        id="email"
                                        label="Email *"
                                        error={error}
                                        {...field}
                                    />
                                )}
                            />
                        )}

                        {contactType === "call" && (
                            <Controller
                                name="phone_number"
                                control={control}
                                rules={{
                                    required: ValidationMessages.required,
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <Input
                                        id="phone_number"
                                        type="tel"
                                        label="Contact Number *"
                                        error={error}
                                        {...field}
                                    />
                                )}
                            />
                        )}

                        <Controller
                            name="role"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "Required",
                            }}
                            render={({ field, fieldState: { error } }) => (
                                <Select
                                    id="role"
                                    label="I am... *"
                                    {...field}
                                    error={error}
                                >
                                    <MenuItem value="educator">
                                        An educator
                                    </MenuItem>
                                    <MenuItem value="parent">A parent</MenuItem>
                                    <MenuItem value="student">
                                        A student
                                    </MenuItem>
                                    <MenuItem value="other">Other</MenuItem>
                                </Select>
                            )}
                        />

                        <Controller
                            name="province"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "Required",
                            }}
                            render={({ field, fieldState: { error } }) => (
                                <Select
                                    id="province"
                                    label="Province *"
                                    {...field}
                                    error={error}
                                >
                                    <MenuItem value="Gauteng">Gauteng</MenuItem>
                                    <MenuItem value="KwaZulu-Natal">
                                        KwaZulu-Natal
                                    </MenuItem>
                                    <MenuItem value="Western Cape">
                                        Western Cape
                                    </MenuItem>
                                    <MenuItem value="Eastern Cape">
                                        Eastern Cape
                                    </MenuItem>
                                    <MenuItem value="Free State">
                                        Free State
                                    </MenuItem>
                                    <MenuItem value="Limpopo">Limpopo</MenuItem>
                                    <MenuItem value="Mpumalanga">
                                        Mpumalanga
                                    </MenuItem>
                                    <MenuItem value="Northern Cape">
                                        Northern Cape
                                    </MenuItem>
                                    <MenuItem value="North West">
                                        North West
                                    </MenuItem>
                                    <MenuItem value="Other">Other</MenuItem>
                                </Select>
                            )}
                        />

                        <Controller
                            name="organization"
                            defaultValue=""
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <Input
                                    id="email"
                                    label="Company/School Name"
                                    error={error}
                                    {...field}
                                />
                            )}
                        />

                        <Controller
                            name="message"
                            defaultValue=""
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <Input
                                    id="message"
                                    label="Message"
                                    error={error}
                                    multiline
                                    {...field}
                                />
                            )}
                        />

                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={2}
                        >
                            {formState.isLoading && <Loader />}
                            {formState.success && (
                                <Typography
                                    variant="paragraph"
                                    sx={{
                                        maxWidth: "240px",
                                        color: Colors.success,
                                    }}
                                >
                                    Your message has been successfully
                                    submitted. We will get back to your shortly!
                                </Typography>
                            )}
                            {formState.error && (
                                <Typography
                                    variant="paragraph"
                                    sx={{
                                        maxWidth: "240px",
                                        color: Colors.error,
                                    }}
                                >
                                    {formState.errorMessage}
                                </Typography>
                            )}
                            <Button
                                variant="contained"
                                disabled={formState.isLoading}
                                type="submit"
                            >
                                Submit
                            </Button>
                        </Stack>
                    </Stack>
                </form>
            </Paper>
        </Container>
    );
};

export default FixedContactForm;
