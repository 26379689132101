import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Outlet, useLocation } from "react-router-dom";

// Components
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import FixedContactForm from "../components/FixedContactForm";

//Utils
import { Colors, NavHeight } from "../utils/constants";

const Content = styled.div`
    position: relative;
    min-height: calc(100vh - (${NavHeight}));
    background: ${Colors.background};
`;

const PublicLayout = () => {
    const { pathname, hash, key } = useLocation();

    useEffect(() => {
        // if not a hash link, scroll to top
        if (hash === "") {
            window.scrollTo(0, 0);
        }
        // else scroll to id
        else {
            setTimeout(() => {
                const id = hash.replace("#", "");
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView();
                }
            }, 500);
        }
    }, [pathname, hash, key]);

    return (
        <>
            <Navbar />
            <Content>
                <GoogleReCaptchaProvider
                    reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}
                    scriptProps={{
                        async: false,
                        defer: false,
                        appendTo: "head",
                        nonce: undefined,
                    }}
                    container={{
                        element: "reacaptcha",
                        parameters: {
                            badge: "bottomleft",
                        },
                    }}
                >
                    <FixedContactForm />
                </GoogleReCaptchaProvider>
                <Outlet />
            </Content>
            <Footer />
            <div id="reacaptcha"></div>
        </>
    );
};

export default PublicLayout;
