import { createTheme } from "@mui/material/styles";
import { Colors } from "./constants";

const ThemeFontFamily = "'Poppins', sans-serif";

const theme = createTheme({
    typography: {
        fontFamily: ThemeFontFamily,
    },
    palette: {
        primary: {
            main: Colors.accent,
            dark: Colors.primary,
        },
        neutral: {
            main: Colors.black,
            light: Colors.white,
        },
        secondary: {
            main: "#11ff00",
        },
        info: {
            main: Colors.yellow,
        },
    },
    shape: {
        borderRadius: 5,
    },
    shadows: {
        0: "",
        1: `${Colors.shadow}`,
        4: `${Colors.shadowBig}`,
        6: "0 6px 20px rgba(255 0 0/ 1)",
        8: `${Colors.shadowBig}`,
        16: "0 6px 20px rgba(255 0 0/ 1)",
        24: `${Colors.shadowBig}`, // Modal
    },
});

const fontSize = 14;
const htmlFontSize = 16;
const coef = fontSize / 14;

theme.typography = {
    pxToRem: (size) => `${(size / htmlFontSize) * coef}rem`,
    h1: {
        fontWeight: 700,
        fontSize: "35px",
        lineHeight: "45px",
        color: Colors.black,
        fontFamily: ThemeFontFamily,

        [theme.breakpoints.up("sm")]: {
            fontSize: "55px",
            lineHeight: "75px",
        },
        [theme.breakpoints.up("lg")]: {
            fontSize: "65px",
            lineHeight: "85px",
        },
    },
    h2: {
        fontWeight: 700,
        fontSize: "30px",
        fontFamily: ThemeFontFamily,

        [theme.breakpoints.up("md")]: {
            fontSize: "50px",
        },
    },
    h3: {
        fontWeight: 700,
        fontSize: "28px",
        fontFamily: ThemeFontFamily,

        [theme.breakpoints.up("md")]: {
            fontSize: "36px",
        },
    },
    h4: {
        fontWeight: 700,
        lineHeight: "34px",
        fontSize: "24px",
        fontFamily: ThemeFontFamily,

        [theme.breakpoints.up("md")]: {
            fontSize: "30px",
        },
    },
    h5: {
        fontWeight: 700,
        fontSize: "18px",
        fontFamily: ThemeFontFamily,

        [theme.breakpoints.up("md")]: {
            fontSize: "20px",
        },
    },
    h6: {
        fontWeight: 700,
        fontSize: "16px",
        fontFamily: ThemeFontFamily,
    },
    subheader: {
        fontWeight: 400,
        lineHeight: "28px",
        fontSize: "14px",
        color: Colors.greyDark,
        fontFamily: ThemeFontFamily,

        [theme.breakpoints.up("md")]: {
            fontSize: "16px",
        },
    },
    paragraph: {
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "24px",
        color: Colors.greyDark,
        fontFamily: ThemeFontFamily,
    },
    meta: {
        fontFamily: ThemeFontFamily,
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "24px",
        color: Colors.greyDark,

        //background: Colors.greyMed,
        border: `2px solid ${Colors.greyDark}`,
        lineHeight: "initial",
        padding: "5px 14px",
        borderRadius: "10px",
        minWidth: "fit-content",
        height: "fit-content",
    },
    tag: {
        fontWeight: 400,
        fontSize: "12px",
        color: Colors.white,
        fontFamily: ThemeFontFamily,
    },
};

theme.components = {
    MuiPaper: {
        styleOverrides: {
            root: {
                borderRadius: 7,
            },
        },
    },
    MuiTypography: {
        defaultProps: {
            variantMapping: {
                paragraph: "p", // This was done because the 'paragraph' & 'subheader' variant was mapping to <span>
                subheader: "p",
            },
        },
    },
    MuiTextField: {
        styleOverrides: {
            root: {
                fontFamily: ThemeFontFamily,
            },
        },
    },
    MuiFormControlLabel: {
        styleOverrides: {
            root: {
                fontFamily: ThemeFontFamily,
            },
        },
    },
    MuiMenuItem: {
        styleOverrides: {
            root: {
                fontFamily: ThemeFontFamily,
            },
        },
    },
    MuiMenuItem: {
        styleOverrides: {
            root: {
                fontFamily: ThemeFontFamily,
            },
        },
    },
    MuiButton: {
        styleOverrides: {
            root: ({ ownerState }) => ({
                fontFamily: ThemeFontFamily,
                fontWeight: 700,
                borderWidth: "2px",
                padding: "12px 20px !important",
                textTransform: "none",
                ...(ownerState.disabled && {
                    backgroundColor: `${Colors.greyMed} !important`,
                    color: Colors.greyDark,
                }),
                ...(ownerState.compact && {
                    padding: "0 !important",
                    color: Colors.white,
                }),
                ...(ownerState.variant === "contained" && {
                    border: `2px solid`,
                    borderColor: Colors.accent,
                }),
                "&:hover": {
                    borderWidth: "2px",
                    ...(ownerState.variant === "contained" && {
                        borderColor: Colors.primary,
                    }),
                },
            }),
        },
        variants: [
            {
                props: { variant: "plain" },
                style: {
                    backgroundColor: "transparent",
                    color: Colors.accent,
                    "&:hover": {
                        color: Colors.primary,
                        backgroundColor: "transparent",
                    },
                },
            },
            {
                props: { variant: "outlined" },
                style: {
                    borderColor: Colors.accent,
                    "&:hover": {
                        color: Colors.primary,
                    },
                },
            },
            {
                props: { variant: "navLink" },
                style: {
                    color: Colors.accent,
                    fontSize: "14px",
                    fontWeight: 600,
                    padding: "0 !important",
                    "&:hover": {
                        color: Colors.primary,
                        backgroundColor: "transparent",
                    },
                },
            },
            {
                props: { variant: "caps" },
                style: {
                    color: Colors.black,
                    borderColor: Colors.lemon,
                    backgroundColor: Colors.lemon,
                    fontSize: "14px",
                    fontWeight: 600,

                    "&:hover": {
                        color: Colors.white,
                        backgroundColor: Colors.success,
                        borderColor: Colors.success,
                    },
                },
            },
        ],
    },
    MuiToggleButtonGroup: {
        styleOverrides: {
            root: {
                border: `2px solid ${Colors.accent}`,
                padding: "4px",
            },
        },
    },
    MuiToggleButton: {
        styleOverrides: {
            root: {
                fontFamily: ThemeFontFamily,
                padding: "10px 25px",
                margin: "4px",
                borderColor: "transparent",
                fontWeight: 700,
                color: Colors.accent,
                transition: "all 0.3s ease",

                "&.MuiToggleButtonGroup-grouped": {
                    borderRadius: "5px !important",
                },
                "&.Mui-selected": {
                    backgroundColor: Colors.accent,
                    color: Colors.white,
                    "&:hover": {
                        backgroundColor: Colors.primary,
                    },
                },
                "&:hover": {
                    backgroundColor: Colors.accent,
                    color: Colors.white,
                },
            },
        },
    },
    MuiToggleButtonGroup: {
        styleOverrides: {
            root: {
                border: `2px solid ${Colors.accent}`,
            },
        },
    },
    MuiSlider: {
        styleOverrides: {
            root: {
                "& .MuiSlider-rail": {
                    backgroundColor: Colors.greyLight,
                },
                "& .MuiSlider-track": {
                    backgroundColor: theme.palette.neutral.light,
                    borderColor: Colors.primary,
                },
                "& .MuiSlider-thumb": {
                    color: theme.palette.neutral.light,
                    width: "14px",
                    height: "14px",
                },
            },
        },
    },
    MuiAccordion: {
        styleOverrides: {
            root: {
                "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                    transform: "rotate(90deg)",
                },
            },
        },
    },
};

export default theme;
