// Next Components
import { useNavigate } from "react-router-dom";

import { useState } from "react";

// Components
import Typography from "../../components/Typography";

// MUI
import MuiAccordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";

// Utils
import { Colors } from "../../utils/constants";

// Icons
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";

const Accordion = ({ title, children, displayIcon = false, href }) => {
    const router = useNavigate();
    const [expanded, setExpanded] = useState(false);
    const handleClick = () => {
        setExpanded(!expanded);
    };

    const handleNavigate = () => {
        router(href);
    };
    return (
        <MuiAccordion
            elevation={0}
            expanded={expanded}
            onClick={() => (displayIcon ? handleClick() : handleNavigate())}
        >
            <AccordionSummary
                expandIcon={
                    displayIcon && (
                        <ExpandMoreRoundedIcon
                            sx={{
                                color: Colors.accent,
                                transition: "all 0.2s ease-in-out",
                                transform: expanded && "rotate(90deg)",
                            }}
                        />
                    )
                }
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{ padding: 0 }}
            >
                <Typography
                    variant={"paragraph"}
                    sx={{
                        color: expanded ? Colors.primary : Colors.accent,
                        fontWeight: expanded ? 700 : 600,
                        textAlign: "center",
                    }}
                >
                    {title}
                </Typography>
            </AccordionSummary>
            <Stack spacing={2} sx={{ padding: 0 }}>
                <Divider
                    variant="fullWidth"
                    sx={{
                        bgcolor: Colors.greyMed,
                        borderColor: Colors.greyMed,
                        display: expanded ? "block" : "none",
                    }}
                />
                <AccordionDetails sx={{ padding: 0 }}>
                    {children}
                </AccordionDetails>
            </Stack>
        </MuiAccordion>
    );
};

export default Accordion;
