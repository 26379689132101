import { css } from "@emotion/react";

const DeviceSizes = {
    phone: 600,
    tablet: 900,
    desktop: 1200,
    large: 1536,
};

const media = Object.keys(DeviceSizes).reduce((finalMedia, size) => {
    return {
        ...finalMedia,
        [size]: function (...args) {
            return css`
                @media (min-width: ${DeviceSizes[size]}px) {
                    ${css(...args)}
                }
            `;
        },
    };
}, {});

export default media;
