import React from "react";
import styled from "@emotion/styled";
import { HashLink } from "react-router-hash-link";

// Utils
import { Colors } from "../utils/constants";

const StyledLink = styled(HashLink)`
    position: relative;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 600;
    transition: 0.3s ease;

    &:hover {
        ${(props) =>
            props.light === "true"
                ? `color: ${Colors.greyMed}`
                : `color: ${Colors.primary}`};
    }

    ${(props) =>
        props.light === "true"
            ? `color: ${Colors.white}`
            : `color: ${Colors.accent}`};
    ${(props) =>
        props.nounderline === "true"
            ? `text-decoration: none`
            : `text-decoration: underline`};
    ${(props) =>
        props.disabled ? `cursor: not-allowed !important` : `cursor: pointer`};
`;

const LinkComp = ({ nounderline, children, ...props }) => {
    return (
        <StyledLink nounderline={nounderline ? "true" : ""} {...props}>
            {children}
        </StyledLink>
    );
};

export default LinkComp;
