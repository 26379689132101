import axios from "axios";

import TokenService from "./token.service";

const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_API_URL,
    timeout: 120000,
    timeoutErrorMessage: "timeout",
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=UTF-8",
    },
});

instance.interceptors.request.use(
    (config) => {
        const token = TokenService.getLocalAccessToken();
        if (token) {
            config.headers["Authorization"] = "Bearer " + token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        const originalConfig = err.config;
        if (
            originalConfig.url !== "/accounts/token/" &&
            originalConfig.url !== "/sso/refresh_token/" &&
            err.response
        ) {
            // Access Token was expired
            if (
                err.response?.data &&
                err.response.data === "User logged out from SSO"
            ) {
                console.log("errr1111", err.response);
                TokenService.removeUser();
                window.location.reload();
                // const { status, data } = await AuthService.getSSOurl()
                // if (status) {
                //     if (data.sso_url) {
                //         window.location.replace(data.sso_url)
                //     }
                // }
            } else if (
                err.response?.data?.detail &&
                err.response.data.detail === "CSRF Failed: CSRF token missing."
            ) {
                console.log("errr222", err.response);
                TokenService.removeUser();
                window.location.reload();
            } else if (
                (err.response.status === 403 || err.response.status === 401) &&
                !originalConfig._retry
            ) {
                originalConfig._retry = true;
                const refreshToken = TokenService.getLocalRefreshToken();
                if (refreshToken) {
                    try {
                        const rs = await instance.post("/sso/refresh_token/", {
                            refresh: TokenService.getLocalRefreshToken(),
                        });
                        const { access } = rs.data;
                        TokenService.updateLocalAccessToken(access);
                        return instance(originalConfig);
                    } catch (_error) {
                        if (_error.response && _error.response.status === 401) {
                            TokenService.removeUser();
                            window.location.reload();
                        }
                        return Promise.reject(_error);
                    }
                }
            }
        }
        return Promise.reject(err);
    }
);

export default instance;
