import React from "react";
import styled from "@emotion/styled";

// Utils
import { Colors } from "../utils/constants";

// MUI
import TextField from "@mui/material/TextField";

const StyledTextField = styled(TextField)`
    .MuiOutlinedInput-root {
        fieldset {
            border: 1px solid ${Colors.greyMed};
        }
        &:hover fieldset {
            border: 2px solid ${Colors.greyMed};
        }
        &.Mui-focused fieldset {
            border-color: ${Colors.accent};
        }
    }

    .MuiInput-root {
        &:hover:not(.Mui-disabled):before {
            border: 2px solid ${Colors.primary};
        }
        &:after {
            border: 2px solid ${Colors.primary};
        }
    }

    .MuiFormHelperText-root {
        color: ${Colors.error};
        font-size: 12px;

        &.Mui-error {
            color: ${Colors.error};
            position: absolute;
            right: 0;
            top: 6px;
            font-size: 12px;
        }
    }

    .MuiInputLabel-root {
        color: ${Colors.greyDark};

        &.Mui-focused {
            color: ${Colors.primary};
        }

        &.Mui-error {
            color: ${Colors.error};
        }
    }

    .Mui-error {
        &:after {
            border-bottom-color: ${Colors.error};
        }

        .MuiOutlinedInput-notchedOutline {
            border-color: ${Colors.error};
        }
    }
`;

const InputField = React.forwardRef(
    (
        {
            label = "none",
            type = "text",
            disabled = false,
            size = "small",
            multiline = false,
            rows = 3,
            error,
            value,
            hintText,
            onChange,
            ...rest
        },
        ref
    ) => {
        return (
            <StyledTextField
                label={label}
                type={type}
                helperText={error && !disabled ? error.message : hintText}
                disabled={disabled}
                size={size}
                variant="outlined"
                fullWidth
                inputRef={ref}
                error={!!error}
                onChange={onChange}
                value={value}
                multiline={multiline}
                rows={rows}
                {...rest}
            />
        );
    }
);

export default InputField;
