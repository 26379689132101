import api from "./api";
import { handleError } from "./utils";

const isBrowser = typeof window !== `undefined`;

const postContactForm = async (values) => {
    if (!isBrowser) return false;

    return await api
        .post(`/forms/contact_us/`, values)
        .then((response) => {
            if (response.status === 201) {
                return {
                    status: true,
                    data: response.data,
                };
            }
        })
        .catch(function (error) {
            return handleError(error);
        });
};

const subscribeToNewsletter = async (email) => {
    if (!isBrowser) return false;

    let lowercaseEmail = email.toLowerCase();
    return await api
        .post(`/forms/newsletter/`, {
            email: lowercaseEmail,
        })
        .then((response) => {
            if (response.status === 201) {
                return {
                    status: true,
                };
            }
        })
        .catch(function (error) {
            return handleError(error);
        });
};

const PublicService = {
    postContactForm,
    subscribeToNewsletter,
};

export default PublicService;
