import React, { useState, useContext } from "react";
import styled from "@emotion/styled";

// Context
import { StateContext } from "../../context/StateContext";

// Utils
import { PageGutter, Colors } from "../../../common/utils/constants";

// MUI
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Components
import Link from "../../../common/components/Link";
import NavLink from "../../components/NavLink";
import Button from "../../components/Button";
import AccordionLink from "./AccordionLink";
import Typography from "../../components/Typography";

// Icons
import Hamburger from "@mui/icons-material/Menu";
import Close from "@mui/icons-material/Close";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";

// Images
import Logo from "../../../images/logo_dark.svg";

const MobileMenuContainer = styled.div`
    background: ${Colors.white};
    position: fixed;
    z-index: 999;
    top: -100vh;
    left: 0;
    height: 100vh;
    width: 100%;
    transition: 0.5s ease;

    display: flex;
    justify-content: center;
    // align-items: center;
    overflow: auto;
    padding-top: 50px;
    ${(props) =>
        props.active &&
        `
        top: 0;
    `};
`;

const Navbar = () => {
    const { isLoggedIn, login, logout, user } = useContext(StateContext);
    const [active, setActive] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuEnter = (event) => {
        if (anchorEl !== event.currentTarget) {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleNavigateLMS = () => {
        var win = window.open(process.env.REACT_APP_LMS_URL, "_self");
        win.focus();
    };

    return (
        <nav>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                    background: Colors.greyLight,
                    padding: {
                        xs: `10px ${PageGutter}`,
                        sm: `16px ${PageGutter}`,
                    },
                }}
            >
                <Link to="/" sx={{ display: "flex" }}>
                    <img alt="resolute education" src={Logo}></img>
                </Link>

                {/* Desktop Menu */}
                <Stack
                    direction="row"
                    spacing={4}
                    alignItems="center"
                    sx={{ display: { xs: "none", lg: "flex" } }}
                >
                    <NavLink
                        id="for-schools-button"
                        to=""
                        onMouseOver={handleMenuEnter}
                        nounderline
                    >
                        For Schools
                        <KeyboardArrowDownRoundedIcon
                            fontSize="small"
                            color="primary"
                            sx={{ ml: 0.5 }}
                        />
                    </NavLink>

                    <Menu
                        id="for-schools-menu"
                        aria-labelledby="for-schools-button"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                        MenuListProps={{ onMouseLeave: handleMenuClose }}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                        sx={{
                            marginTop: "8px",
                        }}
                    >
                        <MenuItem sx={{ p: 2 }}>
                            <NavLink
                                onClick={handleMenuClose}
                                to="/#school-packages"
                                nounderline
                            >
                                School Packages
                            </NavLink>
                        </MenuItem>
                        <MenuItem sx={{ p: 2 }}>
                            <NavLink
                                onClick={handleMenuClose}
                                to="/#robotics-kits"
                                nounderline
                            >
                                Robotics Kits
                            </NavLink>
                        </MenuItem>
                        <MenuItem sx={{ p: 2 }}>
                            <NavLink
                                onClick={handleMenuClose}
                                to="/#professional-development"
                                nounderline
                            >
                                Professional Development
                            </NavLink>
                        </MenuItem>
                        <MenuItem sx={{ p: 2 }}>
                            <NavLink
                                onClick={handleMenuClose}
                                to="/#steam-days"
                                nounderline
                            >
                                Steam Days
                            </NavLink>
                        </MenuItem>
                        <MenuItem sx={{ p: 2 }}>
                            <NavLink
                                onClick={handleMenuClose}
                                to="/#reviews"
                                nounderline
                            >
                                Reviews
                            </NavLink>
                        </MenuItem>
                        <MenuItem sx={{ p: 2 }}>
                            <NavLink
                                onClick={handleMenuClose}
                                to="/#home-faq"
                                nounderline
                            >
                                FAQ
                            </NavLink>
                        </MenuItem>
                    </Menu>

                    <NavLink to="/code-the-nation" nounderline>
                        CAPS Alignment
                    </NavLink>

                    <NavLink to="/about-us" nounderline>
                        About Us
                    </NavLink>
                    <NavLink to="/blog" nounderline>
                        Our Blog
                    </NavLink>
                    <Typography
                        variant="paragraph"
                        component="a"
                        href="https://shop.resolute.education/"
                        target="_blank"
                        sx={{
                            fontWeight: 600,
                            color: Colors.accent,
                            textDecoration: "none",
                            "&:hover": { color: Colors.primary },
                        }}
                    >
                        <Stack position={"relative"}>
                            <Typography
                                variant="paragraph"
                                sx={{
                                    fontSize: 10,
                                    color: Colors.yellow,
                                    position: "absolute",
                                    top: -10,
                                    right: -20,
                                }}
                            >
                                new
                            </Typography>
                            <span>Shop</span>
                        </Stack>
                    </Typography>

                    {/* <Button onClick={handleNavigateLMS} variant="outlined">
                        My Resolute
                    </Button> */}

                    {/* <NavLink to="/schools" nounderline>For Schools</NavLink> */}
                </Stack>
                <Stack direction="row" spacing={2}>
                    {isLoggedIn() && (
                        <Button variant="navLink" onClick={logout}>
                            Sign Out ({user.first_name})
                        </Button>
                    )}
                    {isLoggedIn() ? (
                        <Button
                            onClick={handleNavigateLMS}
                            variant="outlined"
                            sx={{ display: { xs: "none", sm: "flex" } }}
                        >
                            My Resolute
                        </Button>
                    ) : (
                        <>
                            <Button
                                onClick={() => login()}
                                variant="text"
                                sx={{ display: { xs: "none", sm: "flex" } }}
                            >
                                Sign In
                            </Button>
                            <Button
                                onClick={() => login(true)}
                                variant="contained"
                                sx={{ display: { xs: "none", sm: "flex" } }}
                            >
                                Create Free Account
                            </Button>
                        </>
                    )}
                    {/* Mobile Menu */}
                    <IconButton
                        sx={{ display: { xs: "flex", lg: "none" } }}
                        onClick={() => setActive(!active)}
                    >
                        <Hamburger fontSize="large" color="primary" />
                    </IconButton>
                </Stack>

                <MobileMenuContainer
                    sx={{
                        display: { xs: "flex", md: "none" },
                        overflow: "hidden",
                    }}
                    active={active}
                >
                    <Stack spacing={4} alignItems="center">
                        <AccordionLink title="For Schools" displayIcon>
                            <Stack
                                spacing={2}
                                width={"100%"}
                                alignItems={"center"}
                                onClick={() => setActive(!active)}
                            >
                                <NavLink to="/#school-packages" nounderline>
                                    School Packages
                                </NavLink>

                                <NavLink to="/#robotics-kits" nounderline>
                                    Robotics Kits
                                </NavLink>

                                <NavLink
                                    to="/#professional-development"
                                    nounderline
                                >
                                    Professional Development
                                </NavLink>
                                <NavLink to="/#steam-days" nounderline>
                                    Steam Days
                                </NavLink>
                                <NavLink to="/#reviews" nounderline>
                                    Reviews
                                </NavLink>
                                <NavLink to="/#home-faq" nounderline>
                                    FAQ
                                </NavLink>
                            </Stack>
                        </AccordionLink>

                        <Stack
                            spacing={4}
                            alignItems="center"
                            onClick={() => setActive(!active)}
                        >
                            <NavLink to="/code-the-nation" nounderline>
                                CAPS Alignment
                            </NavLink>

                            <NavLink to="/about-us" nounderline>
                                About Us
                            </NavLink>
                            <NavLink to="/blog" nounderline>
                                Our Blog
                            </NavLink>
                            <Typography
                                variant="paragraph"
                                component="a"
                                href="https://shop.resolute.education/"
                                target="_blank"
                                sx={{
                                    fontWeight: 600,
                                    color: Colors.greyDark,
                                    textDecoration: "none",
                                    "&:hover": { color: Colors.primary },
                                }}
                            >
                                <Stack position={"relative"}>
                                    <Typography
                                        variant="paragraph"
                                        sx={{
                                            fontSize: 10,
                                            color: Colors.yellow,
                                            position: "absolute",
                                            top: -10,
                                            right: -20,
                                        }}
                                    >
                                        new
                                    </Typography>
                                    <span>Shop</span>
                                </Stack>
                            </Typography>
                        </Stack>

                        {/* <Button onClick={handleNavigateLMS} variant="outlined">
                            My Resolute
                        </Button> */}

                        {/* <NavLink to="/schools" nounderline>For Schools</NavLink> */}
                        <Stack onClick={() => setActive(!active)}>
                            {isLoggedIn() && (
                                <Button variant="navLink" onClick={logout}>
                                    Sign Out ({user.first_name})
                                </Button>
                            )}
                        </Stack>
                        <Stack
                            spacing={4}
                            alignItems="center"
                            onClick={() => setActive(!active)}
                        >
                            {isLoggedIn() ? (
                                <Button
                                    onClick={handleNavigateLMS}
                                    variant="outlined"
                                >
                                    My Resolute
                                </Button>
                            ) : (
                                <Button onClick={login} variant="outlined">
                                    Sign In / Register
                                </Button>
                            )}
                        </Stack>
                        <IconButton onClick={() => setActive(!active)}>
                            <Close fontSize="large" color="primary" />
                        </IconButton>
                    </Stack>
                </MobileMenuContainer>
            </Stack>
        </nav>
    );
};

export default Navbar;
