import React from "react";
import styled from "@emotion/styled";

// Utils
import { Colors } from "../../common/utils/constants";

const StyledLink = styled.a`
    position: relative;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 600;
    transition: 0.3s ease;

    &:hover {
        ${(props) =>
            props.light === "true"
                ? `color: ${Colors.greyMed}`
                : `color: ${Colors.primary}`};
    }

    ${(props) =>
        props.light === "true"
            ? `color: ${Colors.white}`
            : `color: ${Colors.accent}`};
    ${(props) =>
        props.nounderline === "true"
            ? `text-decoration: none`
            : `text-decoration: underline`};
    ${(props) =>
        props.disabled ? `cursor: not-allowed !important` : `cursor: pointer`};

    &.active {
        color: ${Colors.primary};

        &:after {
            position: absolute;
            content: "";
            background: ${Colors.primary};
            height: 2px;
            width: 100%;
            left: 0;
            bottom: -12px;
        }
    }
`;

const ExternalLink = React.forwardRef(({ children, ...props }, href) => {
    return (
        <StyledLink
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            {...props}
        >
            {children}
        </StyledLink>
    );
});

export default ExternalLink;
