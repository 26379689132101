export const Colors = {
    background: "#F0F1F5",

    primary: "#0914B0",
    accent: "#313BF5",
    white: "#FFFFFF",
    black: "#000000",

    greyLight: "#F0F1F5",
    greyMed: "#D8DAE3",
    greyDark: "#9396A5",
    border: "#DCE6F1",

    success: "#3DA260",
    error: "#E8596D",
    yellow: "#FFB100",
    gold: "#FFB81C",
    lemon: "#00FFC2",

    shadow: "0 6px 20px rgba(0 0 0 / 0.1)",
    shadowBig: "0 6px 30px rgba(0 0 0 / 0.2)",
};

export const AppName = "| Resolute Education";
export const PageGutter = "5%";
export const NavHeight = "80px";

export const ValidationMessages = {
    required: "Required",
    invalidEmail: "Invalid Email",
    invalidPhonenumber: "Invalid Cellphone Number",
    passwrodTooShort: "Password is too short (minimum 6 characters)",
    passwrodNotMatch: "Passwords not match",
};

export const NotificationMessages = {
    auth: {
        authFailed: "Invalid email or password provided.",
        invalidKey: "Invalid key provided!",
        usedKey: "This key has been already used!",
        activationSuccess: "Your account has been successfully activated.",
        userExists: "User with this email already exist!",
        userDoesNotExists: "User with this email does not exist!",
        activationEmailSent: "An activation link has been sent to your email.",
        resetPasswordEmailSent:
            "A reset password link has been sent to your email.",
        passwordResetSuccess:
            "Your password has been reset. You will be redirected to the sign in page shortly...",
    },
};
