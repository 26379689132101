import React, { Suspense, lazy, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { SnackbarProvider } from "notistack";

// GA
import ReactGA from "react-ga4";

// Utils
import ScrollToTop from "./common/router/ScrollToTop";
import theme from "./common/utils/theme";

// Context
import { StateProvider } from "./common/context/StateContext";
import { ThemeProvider } from "@mui/material/styles";

// Components
import Loader from "./common/components/Loader";

// Layout
import PublicLayout from "./common/layout/PublicLayout";
import NotFound from "./common/router/NotFound";

// import SystemMaintenance from "./SystemMaintenance";

// Public
const AsyncSchools = lazy(() => import("./pages/landing/schools/index"));
const AsyncSTEAMDays = lazy(() => import("./pages/landing/schools/STEAMDays"));
const AsyncTeacherTraining = lazy(() =>
    import("./pages/landing/schools/TeacherTraining")
);
const AsyncStreams = lazy(() => import("./pages/landing/schools/Streams"));
const AsyncPackages = lazy(() => import("./pages/landing/schools/Packages"));
const AsyncAbout = lazy(() => import("./pages/landing/about/index"));

const AsyncProductDetails = lazy(() =>
    import("./pages/landing/productDetails/index")
);

const AsyncBlogList = lazy(() => import("./pages/landing/blog/index"));
const AsyncBlogArticle = lazy(() => import("./pages/landing/blog/Article"));
const AsyncCaseStudyArticle = lazy(() =>
    import("./pages/landing/caseStudy/Article")
);

const AsyncTermsOfService = lazy(() =>
    import("./pages/landing/policies/TermsOfService")
);
const AsyncTermsOfServiceProposal = lazy(() =>
    import("./pages/landing/policies/TermsOfServiceProposal")
);

const AsyncPrivacyPolicy = lazy(() =>
    import("./pages/landing/policies/PrivacyPolicy")
);
const AsyncShippingAndReturns = lazy(() =>
    import("./pages/landing/policies/ShippingAndReturns")
);

const AsyncCodeTheNation = lazy(() =>
    import("./pages/landing/code-the-nation/index")
);

// Temporary
const AsyncReRouteView = lazy(() => import("./pages/temporary"));

const App = () => {
    useEffect(() => {
        ReactGA.initialize(process.env.REACT_APP_GA_TAG, {
            testMode: process.env.REACT_APP_GA_TEST_MODE === "true",
        });
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <HelmetProvider>
                    <SnackbarProvider maxSnack={3}>
                        <StateProvider>
                            <ScrollToTop />
                            <Routes>
                                <Route path="/" element={<PublicLayout />}>
                                    <Route
                                        index
                                        element={
                                            <Suspense
                                                fallback={<Loader fullscreen />}
                                            >
                                                <AsyncSchools />
                                            </Suspense>
                                        }
                                    />
                                    <Route
                                        path="schools/steam-days"
                                        element={
                                            <Suspense
                                                fallback={<Loader fullscreen />}
                                            >
                                                <AsyncSTEAMDays />
                                            </Suspense>
                                        }
                                    />
                                    <Route
                                        path="schools/professional-development"
                                        element={
                                            <Suspense
                                                fallback={<Loader fullscreen />}
                                            >
                                                <AsyncTeacherTraining />
                                            </Suspense>
                                        }
                                    />
                                    <Route
                                        path="schools/streams"
                                        element={
                                            <Suspense
                                                fallback={<Loader fullscreen />}
                                            >
                                                <AsyncStreams />
                                            </Suspense>
                                        }
                                    />
                                    <Route
                                        path="schools/packages"
                                        element={
                                            <Suspense
                                                fallback={<Loader fullscreen />}
                                            >
                                                <AsyncPackages />
                                            </Suspense>
                                        }
                                    />

                                    <Route
                                        path="about-us"
                                        element={
                                            <Suspense
                                                fallback={<Loader fullscreen />}
                                            >
                                                <AsyncAbout />
                                            </Suspense>
                                        }
                                    />

                                    <Route
                                        path="product-details/:slug"
                                        element={
                                            <Suspense
                                                fallback={<Loader fullscreen />}
                                            >
                                                <AsyncProductDetails />
                                            </Suspense>
                                        }
                                    />

                                    <Route
                                        path="code-the-nation"
                                        element={
                                            <Suspense
                                                fallback={<Loader fullscreen />}
                                            >
                                                <AsyncCodeTheNation />
                                            </Suspense>
                                        }
                                    />
                                    <Route
                                        path="blog"
                                        element={
                                            <Suspense
                                                fallback={<Loader fullscreen />}
                                            >
                                                <AsyncBlogList />
                                            </Suspense>
                                        }
                                    />
                                    <Route
                                        path="blog/:slug"
                                        element={
                                            <Suspense
                                                fallback={<Loader fullscreen />}
                                            >
                                                <AsyncBlogArticle />
                                            </Suspense>
                                        }
                                    />
                                    <Route
                                        path="case-study/:slug"
                                        element={
                                            <Suspense
                                                fallback={<Loader fullscreen />}
                                            >
                                                <AsyncCaseStudyArticle />
                                            </Suspense>
                                        }
                                    />

                                    <Route
                                        path="terms-of-service"
                                        element={
                                            <Suspense
                                                fallback={<Loader fullscreen />}
                                            >
                                                <AsyncTermsOfService />
                                            </Suspense>
                                        }
                                    />
                                    <Route
                                        path="sch-terms"
                                        element={
                                            <Suspense
                                                fallback={<Loader fullscreen />}
                                            >
                                                <AsyncTermsOfServiceProposal />
                                            </Suspense>
                                        }
                                    />
                                    <Route
                                        path="privacy-policy"
                                        element={
                                            <Suspense
                                                fallback={<Loader fullscreen />}
                                            >
                                                <AsyncPrivacyPolicy />
                                            </Suspense>
                                        }
                                    />

                                    {/* TEMPORARY ROUTE FOR PREVIUS QR CODES */}
                                    <Route
                                        path="/curriculum/:courseId/overview/"
                                        element={
                                            <Suspense
                                                fallback={<Loader fullscreen />}
                                            >
                                                <AsyncReRouteView />
                                            </Suspense>
                                        }
                                    />

                                    {/* <Route
                                        path="shipping-and-returns"
                                        element={
                                            <Suspense fallback={<Loader fullscreen />}>
                                                <AsyncShippingAndReturns />
                                            </Suspense>
                                        }
                                    /> */}
                                </Route>
                                <Route path="*" element={<NotFound />} />
                            </Routes>
                        </StateProvider>
                    </SnackbarProvider>
                </HelmetProvider>
            </BrowserRouter>
        </ThemeProvider>
    );
};

export default App;
