import React from "react";
import styled from "@emotion/styled";

// Material UI
import TextField from "@mui/material/TextField";

// Utils
import { Colors } from "../utils/constants";

const StyledSelectField = styled(TextField)`
    .Mui-error {
        color: ${Colors.error};
        font-size: 12px;
        &:after {
            border-bottom-color: ${Colors.error};
        }

        .MuiOutlinedInput-notchedOutline {
            border-color: ${Colors.error};
        }
    }

    .MuiInputLabel-root {
        color: ${Colors.greyDark};

        &.Mui-focused {
            color: ${Colors.primary};
        }

        &.Mui-error {
            color: ${Colors.error};
        }
    }
`;

const Select = React.forwardRef(
    (
        {
            id,
            name,
            label,
            value,
            onChange,
            onBlur,
            error = null,
            disabled = false,
            filter = false,
            sort = false,
            fullWidth = true,
            isLoading = false,
            children,
            hintText,
            ...rest
        },
        ref
    ) => {
        return (
            <StyledSelectField
                id={id || name}
                name={name}
                select
                label={label}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error !== null}
                helperText={error && !disabled ? error.message : hintText}
                disabled={disabled || isLoading}
                fullWidth={fullWidth}
                size="small"
                inputRef={ref}
                {...rest}
            >
                {children}
            </StyledSelectField>
        );
    }
);

export default Select;
