import React from "react";

// Material
import MuiButton from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

const Button = ({
    type = "button",
    isLoading = false,
    disabled = false,
    fullWidth = false,
    endIcon = null,
    plain = false,
    onClick,
    children,
    sx,
    ...rest
}) => {
    return (
        <MuiButton
            type={type}
            disabled={disabled || isLoading}
            onClick={onClick}
            fullWidth={fullWidth}
            endIcon={
                isLoading ? (
                    <CircularProgress size={20} />
                ) : endIcon ? (
                    endIcon
                ) : null
            }
            sx={sx}
            {...rest}
        >
            {children}
        </MuiButton>
    );
};

export default Button;
