import React, { createContext, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useLocation, useSearchParams } from "react-router-dom";

// lodash
import _cloneDeep from "lodash/cloneDeep";

// API
import AuthService from "../../services/auth.service";

// Componenets
import Loader from "../components/Loader";

// Lodash
import _isEmpty from "lodash/isEmpty";
import _findIndex from "lodash/findIndex";

// Mui
import Slide from "@mui/material/Slide";

export const StateContext = createContext();

const TransitionDown = (props) => {
    return <Slide {...props} direction="down" />;
};

export const StateProvider = ({ children }) => {
    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();
    const [searchParams] = useSearchParams();

    const [notificationMessage, setNotificationMessage] = useState();
    const [loadingAuth, setLoadingAuth] = useState(true);
    const [user, setUser] = useState();

    const [isContactFormOpen, setIsContactFormOpen] = useState(false);
    const [defaultContactMessage, setDefaultContactMessage] = useState("");

    useEffect(() => {
        const accessToken = searchParams.get("access_token");
        const contactForm = searchParams.get("contact_form");

        if (contactForm) {
            setIsContactFormOpen(true);
        }

        if (accessToken) {
            // accessToken would be False if the token has timed out
            if (accessToken === "False") {
                login();
            } else {
                verifyRequestToken();
            }
        } else {
            const user = AuthService.getCurrentUser();
            if (user) {
                setUser(user);
                setLoadingAuth(false);
            } else {
                setLoadingAuth(false);
            }
        }
    }, []);

    useEffect(() => {
        if (notificationMessage) {
            showNotficationMessage(
                notificationMessage.message,
                notificationMessage.type
            );
        }
    }, [notificationMessage]);

    // Toast notifications
    const showNotficationMessage = (message, type) => {
        enqueueSnackbar(message, {
            variant: type,
            anchorOrigin: {
                vertical: "top",
                horizontal: "center",
            },
            TransitionComponent: TransitionDown,
        });
    };

    const handleCustomToggleContactForm = (message) => {
        if (isContactFormOpen) {
            setDefaultContactMessage("");
        } else {
            setDefaultContactMessage(message);
        }

        setIsContactFormOpen(!isContactFormOpen);
    };

    // Authentication
    const verifyRequestToken = async () => {
        const accessToken = searchParams.get("access_token");
        const { status, data } = await AuthService.verifyRequestToken(
            accessToken
        );
        if (status) {
            setUser(data);
            setLoadingAuth(false);

            var newurl =
                window.location.protocol +
                "//" +
                window.location.host +
                window.location.pathname;

            if (newurl.indexOf("code-the-nation") > -1) {
                window.location.replace(process.env.REACT_APP_LMS_URL);
            } else {
                window.history.pushState({ path: newurl }, "", newurl);
            }
        } else {
            setNotificationMessage({
                message: "Error verifying request token",
                type: "error",
            });
        }
    };

    const login = async (register = false) => {
        let next = "";

        if (location.pathname !== "/") {
            next = location.pathname.replace(location.pathname[0], "");
        }

        if (isLoggedIn()) {
            window.location.replace(process.env.REACT_APP_LMS_URL);
        }

        const { status, data } = await AuthService.getSSOurl(next, register);
        if (status) {
            if (data.sso_url) {
                window.location.replace(data.sso_url);
            }
        } else {
            setNotificationMessage({
                message: "Could not request SSO URL.",
                type: "error",
            });
        }
    };

    const isLoggedIn = () => {
        return !!user;
    };

    const logout = async () => {
        const { status } = await AuthService.logout();

        if (status) {
            setUser();
        }
    };

    if (loadingAuth) {
        return <Loader fullscreen />;
    }

    return (
        <StateContext.Provider
            value={{
                user,
                loadingAuth,
                isContactFormOpen,
                defaultContactMessage,
                setNotificationMessage,
                isLoggedIn,
                login,
                logout,
                setIsContactFormOpen,
                handleCustomToggleContactForm,
            }}
        >
            {children}
        </StateContext.Provider>
    );
};
