import React from "react";
import styled from "@emotion/styled";
import Link from "../components/Link";

// Utils
import { PageGutter } from "../utils/constants";

// Components
import Typography from "../components/Typography";

// Images
import Logo from "../../images/logo_dark.svg";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: ${PageGutter};
`;

const NotFound = () => {
    return (
        <Container>
            <img src={Logo} />
            <Typography variant="h4" pt={6}>
                Page Not Found!
            </Typography>
            <Typography
                variant="paragraph"
                mt={4}
                pb={6}
                sx={{ textAlign: "center" }}
            >
                The page you are looking for no longer exists or has been moved.
            </Typography>
            <Link to="/">Resolute Home</Link>
        </Container>
    );
};

export default NotFound;
