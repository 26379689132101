export function isValidEmail(email) {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
}

export function isValidSACellNumber(number) {
    var trimmedNumber = number.replace(/\s/g, "");
    const regex = /^0(6|7|8){1}[0-9]{1}[0-9]{7}$/;
    return regex.test(trimmedNumber);
}

export const isValidContactNumber = (value) => {
    const digitsOnly = value.replace(/\D/g, '');
    const hasMoreThan8Digits = digitsOnly.length >= 8;
    const startsWithPlus = value.startsWith('+');
    return hasMoreThan8Digits && (startsWithPlus || !startsWithPlus);
};